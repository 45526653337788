export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour !"])},
  "messages": {
    "exploreMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez la carte pour localiser et créer les champs de votre entreprise. Naviguez facilement pour une gestion optimale de vos terrain"])},
    "useGearIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez l'icône d'écrou en haut à droite pour accéder aux options de gestion de vos champs. Créez, modifiez et gérez en quelques clics."])},
    "clickPlusButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton '+' pour entrer en mode création de champ. Sélectionnez l'emplacement sur la carte et définissez les détails de votre champ."])},
    "deleteField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour supprimer un champ, cliquez d'abord sur le bouton '-', puis sélectionnez le champ sur la carte. La suppression est simple et intuitive."])},
    "clickPencilIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur l'icône de crayon pour modifier les limites d'un champ existant. Sélectionnez le champ, ajustez ses frontières et enregistrez les modifications."])},
    "useValidationArrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois vos champs créés ou modifiés, utilisez la flèche de validation pour enregistrer vos modifications. Gérez vos champs de manière efficace et organisée."])},
    "interactiveMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte interactive vous permet de visualiser et d'organiser l'espace de votre entreprise agricole. Gérez vos ressources de manière précise."])},
    "simplifiedFieldManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitez d'une gestion de terrain simplifiée : créez, ajustez et supprimez des champs directement sur la carte. Une solution intuitive pour l'agriculture moderne."])},
    "interactiveFieldManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre gestion de champs devient interactive avec notre carte. Créez et ajustez vos espaces de culture en quelques gestes simples."])}
  },
  "infos": {
    "AddAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des informations"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "Phonefixe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone fixe"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "Homme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
    "Femme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
    "sexe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])}
  },
  "Users": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateur"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "UsersInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l'utilisateur"])},
    "SelectUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un utilisateur pour afficher ses informations."])}
  },
  "Login": {
    "Wb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon retour!"])},
    "Sw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou avec"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "CPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le Mot de passe"])},
    "SignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte?"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou Nom d'utilisateur"])}
  },
  "Register": {
    "Cr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "Rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer avec"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou avec"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "CPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le Mot de passe"])},
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déja un compte? Se connecter"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou Nom d'utilisateur"])}
  },
  "header": {
    "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])}
  },
  "AI": {
    "upload_img_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une nouvelle image"])},
    "field_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner le champs"])},
    "upload_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une image"])},
    "appointement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un rendez-vous"])},
    "upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "results_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])}
  },
  "field": {
    "visitDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visite journalière effectuée"])},
    "visitNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visite journalière non effectuée"])}
  },
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "CompanyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des informations sur l'entreprise"])},
  "CompanyUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les informations de l'entreprise"])},
  "UserUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des informations du compte"])},
  "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
  "Addresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "Vsiren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro SIREN/SIRET est valide."])},
  "Fsiren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro SIREN/SIRET n'est pas valide."])},
  "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Température"])},
  "humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidité"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
  "windSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse du vent"])},
  "weather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Météo"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "meteo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir la météo"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "Siret/Siren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET / SIREN"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "AddInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des informations sur le comptes"])},
  "SelectField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner le champs"])},
  "UploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une image"])},
  "Réserverrendez-vous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un rendez-vous"])},
  "GlobalAnalysisField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse globale du champs"])},
  "RetrieveFieldDataDisplayImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer les données du champs et afficher les images"])},
  "Curse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maladie détectée : ESCA / Pourriture noire"])},
  "HealthyLeafConfidenceRate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de confiance de la feuille saine :"])},
  "UnhealthyLeafConfidenceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de confiance de la feuille malade :"])},
  "HealthScore:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score de santé :"])},
  "GlobalAnalysiscompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse globale de l'entreprise"])},
  "RetrieveAllDataDisplayImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer toutes les données et afficher les images"])},
  "ConfidenceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de confiance"])},
  "LogAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des journaux"])},
  "TotalUploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de téléchargements :"])},
  "LastUploadtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière heure de téléchargement :"])},
  "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rôle"])},
  "Employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salarié"])},
  "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un champ"])},
  "messageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Requise"])},
  "messageErrorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone requis"])},
  "toyoung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur doit être âgé d'au moins 18 ans"])},
  "HCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de Confiance de la Feuille Saine :"])},
  "HS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score de Santé :"])},
  "LAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière Date de l'Analyse"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "AA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysé à"])},
  "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé à"])},
  "Confidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confiance"])},
  "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
  "Processing Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de Traitement"])},
  "Predictions:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prédictions:"])},
  "State:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat:"])},
  "diseased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["malade"])},
  "healthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sain"])},
  "Global Analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Globale"])},
  "Last analysis received at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière analyse reçue à"])},
  "Home-One": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAITES LUI"])},
  "Home-Two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIANCE"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AJOUTER"])},
  "Some": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des"])},
  "OnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUR LE COMPTE"])},
  "AndYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ET VOUS ?"])},
  "WhatYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUEL SERA VOTRE"])},
  "Choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHOIX"])},
  "Objectif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon votre objectif, souhaitez vous crée ou rejoindre une entreprise ?"])},
  "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "Join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre"])},
  "Lancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer"])},
  "Your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur votre"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
  "CompanyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de l'entreprise"])},
  "LoginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion. Vérifiez vos identifiants et réessayez."])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertes"])},
  "Numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])}
}