// company.js

import {
  collection,
  query,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  db,
  getCompanyCollection,
  getUserCollection,
  getAuthFirebase,
} from "./firebaseInit";

const companyCollection = getCompanyCollection();
const userCollection = getUserCollection();

async function onAllCompaniesSnapshot(callback) {
  try {
    const q = query(await companyCollection);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const companies = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(companies);
    });
    return unsubscribe;
  } catch (error) {
    console.error("Error in onAllCompaniesSnapshot:", error);
    throw error;
  }
}

async function getCompanyInformation(companyId) {
  try {
    const companyDocRef = doc(await companyCollection, companyId);
    const companySnap = await getDoc(companyDocRef);
    return companySnap.exists() ? companySnap.data() : null;
  } catch (error) {
    console.error("Error in getCompanyInformation:", error);
    throw error;
  }
}

async function getCompany(companyId) {
  try {
    const companyDocRef = doc(await companyCollection, companyId);
    const companySnap = await getDoc(companyDocRef);
    return companySnap.exists() ? companySnap.data() : null;
  } catch (error) {
    console.error("Error in getCompany:", error);
    throw error;
  }
}

async function updateCompanyInformation(companyId, companyData) {
  try {
    const companyDocRef = doc(await companyCollection, companyId);
    await updateDoc(companyDocRef, companyData);
    console.log("Company information successfully updated");
  } catch (error) {
    console.error("Error in updateCompanyInformation:", error);
    throw error;
  }
}

async function deleteCompany(id) {
  try {
    const companyDocRef = doc(await companyCollection, id);
    await deleteDoc(companyDocRef);
    console.log("Company document successfully deleted");
  } catch (error) {
    console.error("Error deleting company document:", error);
  }
}

async function getCompanyFieldsData(companyId) {
  try {
    const companyDocRef = doc(db, "Enterprises", companyId);
    const fieldsCollectionRef = collection(companyDocRef, "Fields");
    const snapshot = await getDocs(fieldsCollectionRef);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error in getCompanyFieldsData:", error);
    throw error;
  }
}

async function getCompanyUsers(companyId) {
  try {
    const users = [];
    // ... (rest of your getCompanyUsers code)

    return users;
  } catch (error) {
    console.error("Error in getCompanyUsers:", error);
    throw error;
  }
}

export {
  onAllCompaniesSnapshot,
  getCompanyInformation,
  getCompany,
  getCompanyUsers,
  deleteCompany,
  updateCompanyInformation,
  getCompanyFieldsData,
};
  
export const createCompany = (user) => {
  // Utiliser l'uid de l'objet user pour la référence du document
  const companyDocRef = doc(companyCollection, user.uid);

  return setDoc(companyDocRef, user)
    .then(() => {
      console.log("User data set successfully in company collection");
    })
    .catch((error) => {
      console.error("Error setting user data in company collection:", error);
    });
};

export const updateCompany = (id, user) => {
  // return companyCollection.doc(id).update(user)
  const companyDocRef = doc(
    companyCollection,
    getAuthFirebase().currentUser.uid
  );

  updateDoc(companyDocRef, user)
    .then(() => {
      console.log("User data updated successfully in company collection");
    })
    .catch((error) => {
      console.error("Error updating user data in company collection:", error);
    });
};

// export const deleteCompany = id => {
//     // return companyCollection.doc(id).delete()
//     const companyDocRef = doc(companyCollection, getAuthFirebase().currentUser.uid);
//
//     deleteDoc(companyDocRef)
//         .then(() => {
//             console.log('Company document successfully deleted');
//         })
//         .catch((error) => {
//             console.error('Error deleting company document:', error);
//         });
// }