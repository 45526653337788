export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "messages": {
    "exploreMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore the map to locate and create fields for your enterprise. Easily navigate for optimal management of your lands"])},
    "useGearIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the gear icon in the top right corner to access field management options. Create, modify and manage with just a few clicks."])},
    "clickPlusButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the '+' button to enter field creation mode. Select the location on the map and define the details of your field."])},
    "deleteField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To delete a field, first click on the '-' button, then select the field on the map. Deletion is simple and intuitive."])},
    "clickPencilIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the pencil icon to modify the boundaries of an existing field. Select the field, adjust its borders, and save the changes."])},
    "useValidationArrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once your fields are created or modified, use the validation arrow to save your changes. Manage your fields in an efficient and organized manner."])},
    "interactiveMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The interactive map allows you to visualize and organize your agricultural enterprise's space. Manage your resources precisely."])},
    "simplifiedFieldManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy simplified land management: create, adjust, and delete fields directly on the map. An intuitive solution for modern agriculture."])},
    "interactiveFieldManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your field management becomes interactive with our map. Create and adjust your cultivation spaces with just a few simple gestures."])}
  },
  "infos": {
    "AddAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AddAcc"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "Phonefixe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone fixe"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "Birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "Homme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men"])},
    "Femme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woman"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "sexe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])}
  },
  "Users": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "UsersInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User information"])},
    "SelectUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a user to view their details."])}
  },
  "Login": {
    "Wb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back!"])},
    "Sw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or with"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "CPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "SignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an Account"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or Username"])}
  },
  "Register": {
    "Cr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
    "Rw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register with"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or with"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "CPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Account"])},
    "Already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account? Login"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or Username"])}
  },
  "header": {
    "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "AI": {
    "upload_img_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new image"])},
    "field_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select field"])},
    "upload_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
    "appointement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book an appointement"])},
    "upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "results_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
  },
  "field": {
    "visitDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily visit completed"])},
    "visitNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily visit not completed"])}
  },
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "CompanyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Company Infomation"])},
  "CompanyUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Company Infomation"])},
  "UserUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Account Information"])},
  "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "Addresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse"])},
  "Vsiren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SIREN/SIRET number is valid."])},
  "Fsiren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SIREN/SIRET number is not valid."])},
  "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
  "humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "windSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Speed"])},
  "weather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weather"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "meteo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Weather"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Siret/Siren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET / SIREN"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "AddInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Account Information"])},
  "SelectField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Field"])},
  "UploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Image"])},
  "Réserverrendez-vous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book an appointment"])},
  "GlobalAnalysisField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Analysis of the Field"])},
  "RetrieveFieldDataDisplayImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve Field Data and Display Images"])},
  "Curse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disease Detected: ESCA / Black rot"])},
  "HealthyLeafConfidenceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthy Leaf Confidence Rate :"])},
  "UnhealthyLeafConfidenceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhealthy Leaf Confidence Rate :"])},
  "HealthScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Score :"])},
  "GlobalAnalysiscompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Analysis of the Company"])},
  "RetrieveAllDataDisplayImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieve All Data and Display Images"])},
  "ConfidenceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidence Rate :"])},
  "LogAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Analysis"])},
  "TotalUploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of uploads :"])},
  "LastUploadtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last upload time :"])},
  "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["role"])},
  "Employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a field"])},
  "messageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Required"])},
  "messageErrorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number required"])},
  "toyoung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User must be at least 18 years old"])},
  "HCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthy Leaf Confidence Rate:"])},
  "HS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Score:"])},
  "LAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Analysis Date"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "AA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysed at"])},
  "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent at"])},
  "Confidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confiance"])},
  "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
  "Processing Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Time"])},
  "Predictions:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictions:"])},
  "State:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State:"])},
  "diseased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diseased"])},
  "healthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["healthy"])},
  "Global Analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Analyse"])},
  "LARA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last analysis received at"])},
  "Home-One": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WILL YOU TRUST"])},
  "Home-Two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIM"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD"])},
  "Some": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOME"])},
  "OnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO THE ACCOUNT"])},
  "AndYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AND YOU ?"])},
  "WhatYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT WILL BE YOUR"])},
  "Choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHOICE"])},
  "Objectif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on your objectives, would you like to create or join a company?"])},
  "Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "Join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
  "Lancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launching"])},
  "Your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUR"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About THE"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "CompanyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Logo"])},
  "LoginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login error. Please check your credentials and try again."])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warning"])},
  "Numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])}
}