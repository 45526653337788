import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/Test.vue"),
  },
  {
    path: "/edit/:id",
    name: "Edit",
    component: () => import("../views/User/EditUser.vue"),
  },
  {
    path: "/editCompany/:id",
    name: "EditCompany",
    component: () => import("../views/Company/Function/EditCompany.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/login/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/login/Register.vue"),
  },
  {
    path: "/additional",
    name: "Additional",
    component: () =>
      import("../views/auth/PostLogin/Additional.vue").catch((err) =>
        console.error(err)
      ),
  },
  {
    path: "/additionalCompany",
    name: "AdditionalCompany",
    component: () => import("../views/auth/PostLogin/AdditionalCompany.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Account.vue"),
  },
  {
    path: "/IA",
    name: "IA",
    component: () => import("../views/useless/IA.vue"),
  },
  {
    path: "/entreprise",
    name: "Entreprise",
    component: () => import("../views/Company/VisuelEntreprise.vue"),
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("../views/Company/Function/CreatedCompany.vue"),
  },
  {
    path: "/joincompany",
    name: "joinCompany",
    component: () => import("../views/Company/Function/JoinCompany.vue"),
  },
  {
    path: "/user",
    name: "User",
    component: () => import("../views/User/User.vue"),
  },
  {
    path: "/option",
    name: "option",
    component: () => import("../views/Option/Option.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.FLYLENS_API_URL),
  routes,
});

export default router;
