<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import { onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";

import {
  USER_STATES,
  getAuthInstance,
  getRegistrationState,
} from "@/services/firebase/user";
import { onAuthStateChanged } from "firebase/auth";

import {} from "@/services/firebase/company";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    onBeforeMount(async () => {
      onAuthStateChanged(await getAuthInstance(), async (user) => {
        if (!user) {
          router.replace("/login");
          return;
        }

        if (
          route.path == "/register" ||
          route.path == "/login" ||
          route.path == "/additional" ||
          route.path == "/company" ||
          route.path == "/additionalCompany" ||
          route.path == "/"
        ) {
          const registrationState = await getRegistrationState(user.uid);

          console.log(registrationState, "registrationState");

          if (registrationState == USER_STATES.MISSING_ADDITIONAL_INFO) {
            router.replace("/additional");
          } else if (
            registrationState == USER_STATES.NOT_CONNECTED_TO_COMPANY
          ) {
            router.replace("/additionalCompany");
          } else if (registrationState == USER_STATES.INCOMPLETE_COMPANY) {
            router.replace("/company");
          } else if (registrationState == USER_STATES.ALL_SET) {
            router.replace("/");
          } else {
            router.replace("/register");
          }
        }
      });
    });
  },
  mounted() {
    let language = localStorage.getItem("language");
    if (language) {
      this.$i18n.locale = language;
    } else {
      // Si aucune langue n'est stockée, utilisez le français par défaut
      this.$i18n.locale = "fr";
    }
  },
};
</script>

<style></style>
