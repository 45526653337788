// user.js

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc, signOut, query, where, getDocs } from "firebase/firestore";
import {
  getAuthFirebase,
  getCompanyCollection,
  getUserCollection,
} from "./firebaseInit"; // Adjust the path as necessary

// Constants for user states
const USER_STATES = {
  NOT_AUTHENTICATED: "Not Authenticated",
  NO_USER_DOCUMENT: "No User Document",
  MISSING_ADDITIONAL_INFO: "Missing Additional Information",
  NOT_CONNECTED_TO_COMPANY: "Not Connected to a Company",
  INCOMPLETE_COMPANY: "Incomplete Company",
  ALL_SET: "All Set",
};

const USER_ROLES = {
  PATRON: "Patron",
  EMPLOYEE: "Employé",
  MANAGER: "Manager",
};

const userCollection = getUserCollection();
const companyCollection = getCompanyCollection();

let auth = await getAuthFirebase();

async function getAuthInstance() {
  return auth;
}

// function getCurrentUser() {
//     return new Promise((resolve, reject) => {
//         const unsubscribe = onAuthStateChanged(auth, (user) => {
//             unsubscribe();
//             resolve(user);
//         }, reject);
//     });
// }

async function getCurrentUser() {
  try {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
          auth,
          (user) => {
            unsubscribe();
            resolve(user);
          },
          (error) => {
            console.error("Error in onAuthStateChanged:", error);
            reject(error);
          }
      );
    });
  } catch (error) {
    console.error("Error in getCurrentUser:", error);
    throw error;
  }
}

async function getUsersWhere(conditions) {
  try {
    let q = query(await userCollection);
    conditions.forEach((condition) => {
      q = query(q, where(condition.field, condition.operator, condition.value));
    });
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs;
  } catch (error) {
    console.error("Error in getUsersWhere:", error);
    throw error;
  }
}

async function login(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error("Error in login:", error);
    throw error;
  }
}

async function logout() {
  try {
    await auth.signOut();
  } catch (error) {
    console.error("Error in logout:", error);
    throw error;
  }
}

async function register(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await setUserInformations(user.uid, {
      email: user.email,
      userId: user.uid,
      // Other user data you want to store
    });

    return user;
  } catch (error) {
    console.error("Error in register:", error);
    throw error;
  }
}

async function deleteUser(userId) {
  try {
    const userDocRef = doc(userCollection, userId);
    await deleteDoc(userDocRef);
    console.log("User document successfully deleted");
  } catch (error) {
    console.error("Error deleting user document:", error);
  }
}

async function retrieveCompanyId(userId) {
  try {
    const userDocRef = doc(userCollection, userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return userDocSnap.data().company;
    }

    return null;
  } catch (error) {
    console.error("Error in retrieveCompanyId:", error);
    throw error;
  }
}

async function retrieveCompaniesFromAuthUser(state) {
  try {
    const currentUserAuth = await getCurrentUser();

    if (currentUserAuth) {
      return await retrieveCompaniesFromUser(currentUserAuth, state);
    } else {
      console.log("No authenticated user found");
    }
  } catch (error) {
    console.error("Error in retrieveCompaniesFromAuthUser:", error);
    throw error;
  }
}

async function retrieveCompaniesFromUser(user) {
  try {
    if (!user || !user.uid) {
      return;
    }

    let companies;

    const userInformation = await getUserInformations(user.uid);

    if (!userInformation || !userInformation.company) {
      console.log("No company information found for user");
      return;
    }

    const companyDocRef = doc(await companyCollection, userInformation.company);
    const docSnap = await getDoc(companyDocRef);

    if (docSnap.exists()) {
      const companyData = { id: companyDocRef.id, ...docSnap.data() };
      companies = [companyData];
    } else {
      console.log("No company found with this ID");
    }

    return companies;
  } catch (error) {
    console.error("Error in retrieveCompaniesFromUser:", error);
    throw error; // Re-throw the error for further handling if needed
  }
}

async function getUserInformations(userId) {
  try {
    const userDocRef = doc(await userCollection, userId);
    const userSnap = await getDoc(userDocRef);
    console.log("Test", userSnap.data(), userId);
    return userSnap.exists() ? userSnap.data() : null;
  } catch (error) {
    console.error("Error in getUserInformations:", error);
    throw error;
  }
}

async function getCurrentUserInformations() {
  try {
    const currentUser = await getCurrentUser();
    const userId = currentUser.uid;

    const userDocRef = doc(await userCollection, userId);
    const userSnap = await getDoc(userDocRef);
    return userSnap.exists() ? userSnap.data() : null;
  } catch (error) {
    console.error("Error in getCurrentUserInformations:", error);
    throw error;
  }
}

async function getUserId() {
  try {
    const currentUser = await getCurrentUser();
    const userId = currentUser.uid;
    console.log(userId, "userId")
    return userId;
  } catch (error) {
    console.error("Error in getCurrentUserInformations:", error);
    throw error;
  }
}

async function updateUserInformations(userId, userInformations) {
  try {
    const userDocRef = await doc(await userCollection, userId);
    await updateDoc(userDocRef, userInformations);
  } catch (error) {
    console.error("Error in updateUserInformations:", error);
    throw error;
  }
}

async function setUserInformations(userId, userInformations) {
  try {
    const userDocRef = await doc(await userCollection, userId);
    await setDoc(userDocRef, userInformations);
  } catch (error) {
    console.error("Error in setUserInformations:", error);
    throw error;
  }
}

async function getRegistrationState() {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return USER_STATES.NOT_AUTHENTICATED;
    }

    const userInformations = await getUserInformations(currentUser.uid);
    if (!userInformations) {
      return USER_STATES.NO_USER_DOCUMENT;
    }

    const requiredFields = ["prenom", "nom", "phone", "naissance", "sexe"];
    const isMissingInfo = requiredFields.some(
        (field) => !userInformations[field]
    );
    if (isMissingInfo) {
      return USER_STATES.MISSING_ADDITIONAL_INFO;
    }

    if (!userInformations.company || !userInformations.rôle) {
      return USER_STATES.NOT_CONNECTED_TO_COMPANY;
    }

    if (userInformations.rôle === USER_ROLES.PATRON) {
      // Implement the logic to check if the company information is complete
      // return USER_STATES.INCOMPLETE_COMPANY
    }

    return USER_STATES.ALL_SET;
  } catch (error) {
    console.error("Error in getRegistrationState:", error);
    throw error;
  }
}

export {
  USER_STATES,
  USER_ROLES,
  getAuthInstance,
  getCurrentUser,
  getUsersWhere,
  login,
  logout,
  register,
  deleteUser,
  retrieveCompanyId,
  retrieveCompaniesFromAuthUser,
  retrieveCompaniesFromUser,
  // retrieveCompany,
  getUserId,
  getUserInformations,
  setUserInformations,
  getCurrentUserInformations,
  updateUserInformations,
  getRegistrationState,
};

export const createUser = (user) => {
  // return userCollection.doc(getAuthFirebase().currentUser.uid).set(user)
  const userDocRef = doc(userCollection, getAuthFirebase().currentUser.uid);

  setDoc(userDocRef, user)
    .then(() => {
      console.log("User data set successfully");
    })
    .catch((error) => {
      console.error("Error setting user data:", error);
    });
};

export const getUser = async (id) => {
  // const user = await userCollection.doc(id).get()
  const userDocRef = doc(userCollection, getAuthFirebase().currentUser.uid);
  const user = await getDoc(userDocRef);

  return user.exists ? user.data() : null;
};

export const updateUser = (id, user) => {
  // return userCollection.doc(id).update(user)
  const userDocRef = doc(userCollection, getAuthFirebase().currentUser.uid);

  updateDoc(userDocRef, user)
    .then(() => {
      console.log("User data updated successfully");
    })
    .catch((error) => {
      console.error("Error updating user data:", error);
    });
};