import { createApp, reactive, onUnmounted, toRefs } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import i18n from "./i18n";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

import {
  getAuthFirebase,
  firebase,
  db,
  getUserCollection,
  getCompanyCollection,
} from "@/services/firebase/firebaseInit";
import { doc, setDoc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";

const userCollection = getUserCollection();
const companyCollection = getCompanyCollection();

// Création de l'élément <img> pour le logo
const link = document.createElement("link");
link.rel = "icon";
link.href = require("./assets/logoWeb.jpg"); // Chemin vers votre fichier logo

// Ajout du logo à la balise <head>
document.head.appendChild(link);

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(VCalendar)
  .use(VueTelInput);

// Title
document.title = "Flylens Web interface";

// Script
const script = document.createElement("script");
script.src = "/hotjar-tracking.js";
script.type = "text/javascript";
script.async = true;
document.head.appendChild(script);

// Meta Tags
const metaTags = [
  { name: "description", content: "Flylens Web interface" },
  {
    name: "keywords",
    content: "Flylens, Web, interface, Drone, AI, Agriculture",
  },
  { name: "author", content: "Flylens" },
];

metaTags.forEach((tagInfo) => {
  const meta = document.createElement("meta");
  meta.name = tagInfo.name;
  meta.content = tagInfo.content;
  document.head.appendChild(meta);
});

await router.isReady();
app.mount("#app");
