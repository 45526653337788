import axios from "axios";

// const url_api_flylens = process.env.VUE_APP_FLYLENS_API_URL
const url_api_flylens = "https://prod.flylens.fr:3001";

async function request(url, method, options) {
  if (!url) {
    throw new Error("No url provided");
  }

  if (!method) {
    throw new Error("No method provided");
  }

  if (!options) {
    options = {};
  }

  let response;
  if (method === "GET") {
    response = await axios.get(url, options);
  } else if (method === "POST") {
    response = await axios.post(url, options);
  } else if (method === "PUT") {
    response = await axios.put(url, options);
  } else if (method === "DELETE") {
    response = await axios.delete(url, options);
  }

  return response;
}

// ANALYSES

async function massRetrieve(fieldId, companyId) {
  const response = await request(
    `${url_api_flylens}/analyses/mass_retrieve?field_id=${fieldId}&company_id=${companyId}`,
    "GET"
  );
  console.log(response.data);
  console.log(fieldId);
  return response.data;
}

async function stats(companyId) {
  const response = await request(
    `${url_api_flylens}/analyses/stats?company_id=${companyId}`,
    "GET"
  );
  return response.data;
}

export { massRetrieve, stats };
