import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // or 'firebase/firestore/lite'
import { collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyABMAoByOL1ZI50jjttuCq4UcFIpFso0Lk",
  authDomain: "flylens.firebaseapp.com",
  databaseURL: "https://flylens-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "flylens",
  storageBucket: "flylens.appspot.com",
  messagingSenderId: "248230918332",
  appId: "1:248230918332:web:8d91d06111611aa508f8b9",
  measurementId: "G-BCR22M1VTD",
  // databaseId: "demo"
};

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
const getUserCollection = () => collection(db, "Users");
const getCompanyCollection = () => collection(db, "Enterprises");

function getAuthFirebase() {
  return getAuth(firebase);
}

export {
  firebase,
  db,
  getUserCollection,
  getCompanyCollection,
  getAuthFirebase,
};
