<template>
  <div id="navContainer">
    <button id="navToggle" @click="toggleNavBar" v-show="!isNavBarOpen">
      <i class="fas fa-bars"></i>
    </button>
    <div ref="navBar" class="navBar" v-show="isNavBarOpen">
      <router-link to="/" class="nav-logo">
        <img style="height: 50px" src="../assets/logo_flylens_small.png" alt="Logo Flylens" />
      </router-link>
      <ul class="nav-links">
        <li style="padding-right: 30px; border-right: 1px solid #ccc">
          <router-link to="/">
            <div class="nav-icon" :class="{ active: activeTab === '/' }">
              <i class="fa fa-home"></i>
            </div>
            <span>{{ $t("header.home") }}</span>
          </router-link>
        </li>
        <li style="padding-right: 30px; border-right: 1px solid #ccc">
          <router-link to="/Entreprise">
            <div class="nav-icon" :class="{ active: activeTab === '/Entreprise' }">
              <i class="fa fa-user"></i>
            </div>
            {{ $t("header.company") }}
          </router-link>
        </li>
        <li>
          <router-link to="/option">
            <div class="nav-icon" :class="{ active: activeTab === '/option' }">
              <i class="fas fa-cog"></i>
            </div>
            {{ $t("header.settings") }}
          </router-link>
        </li>
      </ul>
      &nbsp;
      <button class="closeNav" @click="toggleNavBar">✕</button>
    </div>
  </div>
  <div id="menuContainer">
    <button id="menuButton" @click="toggleMenu" v-show="!isMenuOpen">></button>
    <div ref="menuContent" class="menuContent" v-show="isMenuOpen">
      <div v-for="field in fields" :key="field.id" class="field-item" :style="linkStyle(field)"
        @click="centerOnField(field.id)">
        <div class="field-name">{{ field.name }}</div>
        <div class="field-alerts">
          <div class="alert-rectangle">{{ field.alerts }} {{ $t("Warning") }}</div>
        </div>
        <div class="field-visit">
          {{ field.visitDone ? $t("field.visitDone") : $t("field.visitNotDone") }}
        </div>

        <div class="field-separator"></div>
      </div>
      &nbsp;&nbsp;&nbsp;
      <button class="closeMenu" @click="toggleMenu">&lt;</button>
    </div>
  </div>
  <div class="map-container">
    <!-- Map -->
    <div ref="mapRef" style="height: 100vh; width: 100vw" id="mapContainer"></div>
  </div>
</template>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css";

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

#navContainer {
  position: relative;
  bottom: 5%;
  left: 5%;
  z-index: 1;
}

#navToggle {
  background-color: #4caf50;
  /* Couleur de fond similaire à celle des boutons du menu */
  border: none;
  /* Pas de bordure */
  color: white;
  /* Couleur du texte */
  font-size: 20px;
  /* Taille du texte */
  cursor: pointer;
  border-radius: 50%;
  /* Rendre le bouton circulaire */
  width: 50px;
  /* Largeur du bouton */
  height: 50px;
  /* Hauteur du bouton */
  text-align: center;
  /* Centrer le texte */
  line-height: 50px;
  /* Centrer le texte verticalement */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* Ombre pour un effet 3D */
  position: fixed;
  /* Position fixe */
  top: 5%;
  /* Position en haut */
  z-index: 1;
  /* S'assurer qu'il est au-dessus des autres éléments */
  transition: background-color 0.3s, box-shadow 0.3s;
  /* Transition pour l'interaction */
}

#navToggle:hover {
  background-color: #3e8e41;
  /* Couleur au survol */
  box-shadow: 0 2px #666;
  /* Ombre au survol */
}

#navToggle:active {
  background-color: #3e8e41;
  /* Couleur lors du clic */
  box-shadow: 0 1px #333;
  /* Ombre lors du clic */
}

.navBar {
  background-color: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  /* Utilisez flex pour un alignement correct */
  flex-direction: row;
  /* Alignez les éléments horizontalement */
  align-items: center;
  /* Centrez les éléments verticalement */
  position: absolute;
  top: 100%;
  /* Juste en dessous du bouton */
  margin-top: 2%;
  transition: all 0.4s ease;
  overflow: hidden;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  /* initial state */
  width: auto;
  /* Animation de transformation */
  z-index: 20;
}

.navBar.open {
  transform: translateX(0);
  /* Affichez la barre lorsqu'elle est ouverte */
  opacity: 1;
  /* final state */
}

.nav-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* Empêche le passage à la ligne */
  padding: 0;
  margin: 0;
  padding-left: 20px;
  list-style-type: none;
}

.nav-links li:not(:last-child) {
  border-right: 1px solid #e0e0e0;
  padding-right: 20px;
}

.nav-links li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  /* Espacement entre les éléments */
}

.nav-icon.active {
  color: #4caf50;
}

.nav-links a,
.nav-links router-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-links li:last-child {
  margin-right: 0;
  /* Pas de marge pour le dernier élément */
}

.nav-links a,
.nav-links router-link {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-links a:hover,
.nav-links router-link:hover {
  color: #333;
}

.closeNav {
  margin-left: 15px;
  background-color: transparent;
  border: none;
  color: #033323;
  font-size: 20px;
  cursor: pointer;
}

.nav-logo img {
  height: 30px;
  /* ou la taille souhaitée */
  margin-right: 10px;
}

.nav-logo span {
  font-size: 20px;
}

#menuContainer {
  position: absolute;
  bottom: 5%;
  left: 5%;
  z-index: 1;
}

#menuButton {
  position: absolute;
  bottom: 5%;
  /* Adjust as needed */
  margin-left: 5%;
  border-radius: 50%;
  /* Makes it circular */
  width: 50px;
  /* Size of the button */
  height: 50px;
  /* Size of the button */
  font-size: 20px;
  /* Adjust the arrow size */
  cursor: pointer;
  z-index: 1;
  /* Add more styling as needed */
  background-color: #4caf50;
  /* Green background */
  border: none;
  /* No border */
  color: white;
  /* White arrow color */
  text-align: center;
  /* Center the arrow */
  text-decoration: none;
  /* No underline */
  display: inline-block;
  /* Inline-block display */
  line-height: 50px;
  transition: width 0.4s;
  /* Vertically center the arrow in the button */

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* Shadow for 3D effect */
  transition-duration: 0.4s;
  /* Transition effect when mouse hovers */

  /* Styling for when the mouse hovers over the button */
  &:hover {
    background-color: #3e8e41;
    /* Darker shade of green */
    box-shadow: 1 2px #999;
    /* Shadow effect */
  }

  /* Styling for when the button is active or clicked */
  &:active {
    background-color: #3e8e41;
    box-shadow: 0 2px #666;
    /* Shadow effect */
    transform: translateY(4px);
    /* Slightly move the button down */
  }
}

.menuContent {
  display: flex;
  overflow-x:scroll;
  white-space: nowrap;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 100vh;
  /* Animation de la largeur maximale */
}

.menuContent.open {
  width: 100vw;
  /* ou la largeur désirée */
}

.closeMenu {
  background-color: rgba(0, 0, 0, 0.1);
  /* Fond légèrement transparent */
  color: #4caf50;
  /* Couleur initiale du texte */
  border-radius: 50%;
  /* Rendre le bouton circulaire */
  width: 40px;
  /* Largeur du bouton */
  height: 40px;
  /* Hauteur du bouton */
  padding: 10px;
  /* Ajouter un peu d'espace autour du texte */
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.closeMenu:hover {
  background-color: #4caf50;
  /* Couleur de fond au survol */
  color: white;
  /* Couleur du texte au survol */
}

.field-item {
  display: inline-block;
  padding: 2px;
  padding-right: 30px;
  margin: 10px;
  border-right: 1px solid #ccc;
  /* ou un autre style de votre choix */
  cursor: pointer;
  /* si vous voulez que les éléments soient cliquables */
}

.field-item:hover {
  background-color: #f0f0f0;
  /* Changement de couleur au survol */
}

.field-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.alert-rectangle {
  background-color: #ff0000;
  /* Couleur du rectangle, à ajuster */
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.field-visit {
  margin-top: 5px;
  font-style: italic;
}

.field-item:not(:last-child) .field-separator {
  margin-top: 10px;
}

.field-separator {
  margin-right: 10px;
  /* Espace après la séparation */
}

.image-popup-content {
  text-align: center;
  max-width: 1000px;
}

.image-popup-content img {
  max-width: 100%;
  /* Assurez-vous que l'image ne dépasse pas la largeur de la popup */
  height: auto;
  /* Gardez l'aspect ratio */
  display: block;
  /* Élimine les marges par défaut */
  margin: 0 auto;
  /* Centrer l'image */
}

.leaflet-popup .image-popup-content img {
  max-width: 100%;
  height: auto;
}

.leaflet-popup .leaflet-popup-content {
  max-width: 800px;
}

.image-info {
  margin-top: 10px;
  width: 1000px;
}
</style>

<style>
body {
  overflow-x: hidden;
}

.main-content {
  position: relative;
  background-color: #bcb0b0;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-x: hidden;
}

.content-overlay {
  text-align: center;
  width: 100%;
  z-index: 1;
  margin-left: 250px;
}

.map-content {
  margin-top: 50px;
  margin-left: 350px;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  overflow-x: hidden;
}
</style>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import seedrandom from 'seedrandom';

import { getCompanyFieldsData } from "@/services/firebase/company";
import { massRetrieve, stats } from "@/services/flylens_api/calls";

import { toRaw } from "vue";
import {
  getCurrentUserInformations,
} from "../services/firebase/user";

export default {
  components: {
  },
  data() {
    return {
      map: null,
      isMenuOpen: false,
      isNavBarOpen: false,
      companyId: null, //TODO async
      polygon: [],
      fields: [],
      companyData: null,
      appointment: { date: "", time: "", field: "" },
      imagesData: [],
      lastAnalysisDate: null,
      searchPopup: null,
    };
  },
  methods: {
    initMap() {
      this.map = L.map("mapContainer", {
        editable: true,
        zoomControl: false,
      }).setView([48.8566, 2.3522], 10);
      L.tileLayer(
        "https://wxs.ign.fr/essentiels/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIXSET=PM&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&STYLE=normal&FORMAT=image/jpeg"
      ).addTo(this.map);

      L.control
        .zoom({
          position: "topright", // ou 'topleft', 'bottomleft', 'bottomright'
        })
        .addTo(this.map);
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleNavBar() {
      this.isNavBarOpen = !this.isNavBarOpen;
    },
    centerOnField(fieldId) {
      const selectedField = this.polygon.find((p) => p._fieldId === fieldId);
      if (selectedField) {
        this.map.fitBounds(selectedField.getBounds());
      }
    },
    async loadUser() {
      const currentUserInformation = await getCurrentUserInformations();
      this.companyId = currentUserInformation.company;
    },
    async loadFields() {
      try {
        const fieldsData = await getCompanyFieldsData(this.companyId);

        for (const fieldData of fieldsData) {
          const latlngs = fieldData.polygons.map((coordString) => {
            const [lat, lng] = coordString.split(", ");
            return L.latLng(parseFloat(lat), parseFloat(lng));
          });

          const polygon = L.polygon(latlngs).addTo(this.map);
          polygon._fieldId = fieldData.id;
          this.polygon.push(polygon);

          // Comptage des images non saines pour ce champ
          const unhealthyImagesCount = await this.countUnhealthyImagesForField(
            fieldData.id,
            this.companyId
          );
          fieldData.alerts = unhealthyImagesCount;

          this.fields.push({
            id: fieldData.id,
            name: fieldData.name,
            alerts: fieldData.alerts,
          });
        }
        this.fetchCompanyWideData();
        this.fetchAndDisplayImages(this.fields);

      } catch (error) {
        console.error("Erreur lors du chargement des champs : ", error);
        // Gérer l'erreur comme nécessaire
      }
    },

    async fetchCompanyWideData() {
      try {
        const response = await stats(this.companyId);
        this.companyData = response.data;
      } catch (error) {
        console.error("Error fetching company-wide data", error);
      }
    },
    async fetchAndDisplayImages(fields) {
      try {
        for (let field of fields) {
          try {
            const imagesData = await this.fetchImagesData(field.id);

            this.imagesData = imagesData; // Assurez-vous que this.imagesData est mis à jour ici
            this.lastAnalysisDate = this.getLastAnalysisDate(imagesData);

            // Utiliser filterUnhealthyImages pour obtenir les images malades
            const unhealthyImages = this.filterUnhealthyImages();

            // Traiter uniquement les images malades
            const processedImages = await this.processImagesWithCanvas(
              unhealthyImages
            );
            this.displayMarkers(processedImages, field.id);
          } catch (innerError) {
            console.error(
              `Error fetching images for field ${field.id}:`,
              innerError
            );
          }
        }
      } catch (error) {
        console.error("Error fetching images", error);
      }
    },
    async fetchImagesData(fieldId) {
      const response = await massRetrieve(fieldId, this.companyId);
      return response.images_analysis;
    },
    getLastAnalysisDate(imagesData) {
      if (imagesData.length > 0) {
        const lastAnalysisIndex = imagesData.length - 1;
        const lastAnalysis = imagesData[lastAnalysisIndex].analyses[0];
        return lastAnalysis.received_at;
      }
      return null;
    },

    displayMarkers(processedImages, fieldId) {
      var myIcone = L.icon({
        iconUrl: "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
        iconSize: [38, 38],
      });
      const popupOptions = {
        maxWidth: 800,
        maxHeight: 600,
        autoPan: false
      };

      processedImages.forEach((image) => {
        // Générer des coordonnées stables pour chaque image
        console.log("img", image);
        const coords = this.generateStableRandomCoords(image.input_image_id, fieldId);

        if (coords) {
          const marker = L.marker(
            [coords.latitude, coords.longitude],
            { icon: myIcone }
          ).addTo(toRaw(this.map));

          marker.bindPopup(this.createPopupContent(image), popupOptions);
          marker.on('popupclose', (e) => {
            console.log("Popup fermée", e);
          });
        } else {
          console.log("Impossible de générer des coordonnées pour l'image", image);
        }
      });
    },

    generateStableRandomCoords(imageId, fieldId) {
      const fieldPolygon = this.polygon.find(p => p._fieldId === fieldId);
      if (!fieldPolygon) {
        console.log("Champ non trouvé pour l'ID:", fieldId);
        return null;
      }

      const bounds = fieldPolygon.getBounds();
      const minLat = bounds.getSouthWest().lat;
      const maxLat = bounds.getNorthEast().lat;
      const minLng = bounds.getSouthWest().lng;
      const maxLng = bounds.getNorthEast().lng;

      const marginFactor = 0.1; // Réduire la marge pour avoir une plus grande zone de randomisation
      const latRange = (maxLat - minLat) * marginFactor;
      const lngRange = (maxLng - minLng) * marginFactor;

      const seed = `${imageId}-${fieldId}`;
      const random = seedrandom(seed);
      const latitude = minLat + latRange + (maxLat - minLat - 3 * latRange) * random();
      const longitude = minLng + lngRange + (maxLng - minLng - 3 * lngRange) * random();

      return { latitude, longitude };
    },

    async processImagesWithCanvas(imagesData) {
      return Promise.all(
        imagesData.map((image, index) => {
          return new Promise((resolve, reject) => {
            const canvasElement = document.createElement("canvas");
            canvasElement.id = `canvas-${index}`;
            document.body.appendChild(canvasElement); // Ajout temporaire au DOM pour le rendu

            const ctx = canvasElement.getContext("2d");
            const img = new Image();

            img.onload = () => {
              canvasElement.width = img.width;
              canvasElement.height = img.height;
              ctx.drawImage(img, 0, 0);

              // Traitement des analyses et ajouts des annotations sur le canvas
              image.analyses.forEach((analysis) => {
                analysis.result.predictions.forEach((prediction) => {
                  // Ajout de rectangles et de textes basés sur les prédictions
                  ctx.beginPath();
                  ctx.rect(
                    prediction.x - prediction.width / 2,
                    prediction.y - prediction.height / 2,
                    prediction.width,
                    prediction.height
                  );
                  ctx.strokeStyle =
                    prediction.class === "Unhealthy-leaf" ? "orange" : "purple";
                  ctx.lineWidth = 6;
                  ctx.stroke();

                  ctx.font = "20px Arial";
                  ctx.fillStyle = "white";
                  ctx.fillText(
                    prediction.class,
                    prediction.x,
                    prediction.y - 10
                  );
                });
              });

              // Convertir le canvas en URL de données
              const annotatedImageUrl = canvasElement.toDataURL();

              // Nettoyer en supprimant le canvas du DOM
              document.body.removeChild(canvasElement);

              // Retourner les données nécessaires pour les cartes
              resolve({
                ...image, // Conservation des données existantes de l'image
                annotatedImageUrl: annotatedImageUrl, // Ajout de l'URL de l'image annotée
              });
            };

            img.onerror = reject;
            img.crossOrigin = "anonymous"; // Nécessaire pour les images provenant de sources externes
            img.src = image.input_image_url;
          });
        })
      );
    },

    filterUnhealthyImages() {
      return this.imagesData.filter((image) => {
        let healthyCount = 0,
          otherPredictionsCount = 0;

        image.analyses.forEach((analysis) => {
          analysis.result.predictions.forEach((prediction) => {
            if (prediction.class === "Healthy-leaf") {
              healthyCount++;
            } else {
              otherPredictionsCount++;
            }
          });
        });
        return otherPredictionsCount >= healthyCount;
      });
    },

    createPopupContent(image) {
      const averageConfidence = this.calculateAverageConfidence(image.analyses[0].result.predictions);
      const score = this.confidenceToScore(averageConfidence);
      const color = this.getScoreColor(score);
      const diseaseName = this.getDiseaseName(image.analyses[0].result.predictions[0].class_id);

      const content = document.createElement("div");
      content.innerHTML = `
      <div class="image-popup-content">
        <img src="${image.annotatedImageUrl}" alt="Image annotée"/>
        <div class="image-info">
          <p>Date de l'analyse : ${this.formatDate(image.analyses[0].sent_at)}</p>
          <p style="color: ${color};">Score de santé : ${score}</p>
          <p>Maladie : ${diseaseName}</p>
        </div>
      </div>
    `;
      return content;
    },

    getDiseaseName(classId) {
      const diseaseNames = {
        0: "Leaf Healthy",
        1: "Esca",
        2: "Blackrot",
        3: "Mildew",
        4: "Powdery Mildew",
        5: "Erineum",
        6: "Flavescence Dorée"
      };
      return diseaseNames[classId] || "Unknown Disease";
    },

    calculateAverageConfidence(predictions) {
      const totalConfidence = predictions.reduce((sum, prediction) => sum + prediction.confidence, 0);
      return totalConfidence / predictions.length;
    },

    confidenceToScore(confidence) {
      if (confidence >= 0.8) return 'E'; // Plus bas score
      else if (confidence >= 0.6) return 'D';
      else if (confidence >= 0.4) return 'C';
      else if (confidence >= 0.2) return 'B';
      else return 'A'; // Plus haut score
    },
    getScoreColor(score) {
      switch (score) {
        case 'E': return '#ff0000'; // Rouge foncé
        case 'D': return '#ff4500'; // Rouge orangé
        case 'C': return '#ffa500'; // Orange
        case 'B': return '#c6c902'; // Jaune
        case 'A': return '#008000'; // Vert
        default: return '#000000'; // Noir (par défaut)
      }
    },
    formatHealthScore(score) {
      // Remplacer ceci par la logique de formatage du score de santé
      return score ? `Score: ${score}` : "Non disponible";
    },
    formatDate(dateString) {
      // Remplacer ceci par la logique de formatage de la date
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    linkStyle(link) {
      if (link !== this.fields[this.fields.length - 1]) {
        return {
          paddingRight: "30px",
          borderRight: "1px solid #ccc",
        };
      } else {
        return {
          paddingRight: "0px",
          borderRight: "none",
        };
      }
    },
    async countUnhealthyImagesForField(fieldId) {
      let unhealthyCount = 0;
      try {
        const response = await massRetrieve(fieldId, this.companyId);
        const imagesData = response.images_analysis;
        imagesData.forEach((image) => {
          let healthyCount = 0,
            otherPredictionsCount = 0;
          image.analyses.forEach((analysis) => {
            analysis.result.predictions.forEach((prediction) => {
              if (prediction.class === "Healthy-leaf") {
                healthyCount++;
              } else {
                otherPredictionsCount++;
              }
            });
          });
          if (otherPredictionsCount >= healthyCount) {
            unhealthyCount++;
          }
        });
      } catch (error) {
        console.error("Error fetching images for field", error);
      }
      return unhealthyCount;
    },
  },
  async mounted() {
    await this.loadUser();
    this.initMap();
    this.loadFields();
  },
  computed: {
    activeTab() {
      return this.$route.path;
    },
  },
};
</script>